/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DebugEntry } from '../models/DebugEntry';
import type { DebugMessage } from '../models/DebugMessage';
import type { Device } from '../models/Device';
import type { Metrics } from '../models/Metrics';
import type { ResponseMessage } from '../models/ResponseMessage';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DevicesService {

    /**
     * Add a device to an organization
     * This endpoint allows an admin of an organization to add a device to the organization.
     * @param orgId ID of the organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Successfully added the device
     * @throws ApiError
     */
    public static addDeviceToOrg(
        orgId: string,
        authorization: string,
        requestBody: {
            /**
             * Serial number of the device
             */
            serial: string;
            /**
             * Operating system of the device
             */
            os?: string;
            /**
             * DeviceHub software version
             */
            dh_sw?: string;
            /**
             * Application software versions
             */
            app_sw_versions?: string;
            /**
             * Security patch version of the device
             */
            security_patch_version?: string;
            /**
             * Date of provisioning the device
             */
            provisioned_date?: string;
            /**
             * Device certificate
             */
            device_certificate?: string;
            /**
             * Device topic
             */
            device_topic?: string;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/add_devices',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Device with this serial number already exists`,
                403: `Permission denied`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * List devices in an organization and its sub-organizations
     * This endpoint retrieves the devices in the given organization and its sub-organizations
     * @param orgId ID of the organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param cursor Cursor
     * @param limit Limit
     * @returns any Devices retrieved successfully
     * @throws ApiError
     */
    public static getOrgsListDevices(
        orgId: string,
        authorization: string,
        cursor?: string,
        limit?: string,
    ): CancelablePromise<{
        message?: string;
        devices: Array<Device>;
        cursor?: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/list_devices',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'cursor': cursor,
                'limit': limit,
            },
            errors: {
                403: `Forbidden`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Stats devices in an organization and its sub-organizations
     * This endpoint retrieves the devices in the given organization and its sub-organizations
     * @param orgId ID of the organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns any Devices retrieved successfully
     * @throws ApiError
     */
    public static getOrgsStatsDevices(
        orgId: string,
        authorization: string,
    ): CancelablePromise<{
        disconnected: number;
        connected: number;
        total: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/stats_devices',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                403: `Forbidden`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Remove a device from a sub-organization
     * This endpoint removes a device from a sub-organization and moves it back to the parent organization.
     * @param orgId Organization ID
     * @param subOrgId Sub-Organizations ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Device successfully removed from the sub-organization and moved back to the parent organization.
     * @throws ApiError
     */
    public static removeDeviceFromSubOrg(
        orgId: string,
        subOrgId: string,
        authorization: string,
        requestBody?: {
            /**
             * Device serial number
             */
            serial: string;
        },
    ): CancelablePromise<{
        /**
         * Success message
         */
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/remove_device',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `You don't have the required permissions to remove devices.`,
                404: `The specified sub_org or device does not exist.`,
            },
        });
    }

    /**
     * List all devices in a sub-organization
     * This endpoint returns a list of all devices that belong to a specified sub-organization.
     * @param orgId Organization ID
     * @param subOrgId Sub-Organizations ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param cursor Cursor
     * @param limit Limit
     * @returns any Devices successfully fetched.
     * @throws ApiError
     */
    public static listDevicesInSubOrg(
        orgId: string,
        subOrgId: string,
        authorization: string,
        cursor?: string,
        limit?: string,
    ): CancelablePromise<{
        message?: string;
        devices: Array<Device>;
        cursor?: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/list_devices',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'cursor': cursor,
                'limit': limit,
            },
            errors: {
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Stats devices in an organization and its sub-organizations
     * This endpoint retrieves the devices in the given organization and its sub-organizations
     * @param orgId ID of the organization
     * @param subOrgId Sub-Organizations ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns any Devices retrieved successfully
     * @throws ApiError
     */
    public static getOrgsSuborgsStatsDevices(
        orgId: string,
        subOrgId: string,
        authorization: string,
    ): CancelablePromise<{
        disconnected: number;
        connected: number;
        total: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/stats_devices',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                403: `Forbidden`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Move a device to a sub-organization
     * This endpoint allows an admin to move a device to a specific sub-organization
     * @param orgId ID of the organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Device successfully moved
     * @throws ApiError
     */
    public static postOrgsDevicesMoveToSuborg(
        orgId: string,
        authorization: string,
        requestBody: {
            /**
             * ID of the sub-organization
             */
            sub_org_id: string;
            /**
             * Serial number of the device
             */
            serial: string;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/devices/move_to_suborg',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get device registration key for a parent organization
     * @param orgId ID of the organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getDeviceRegistrationKeyOrg(
        orgId: string,
        authorization: string,
    ): CancelablePromise<{
        registration_key?: string;
        valid_till?: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/get_device_registration_key',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
        });
    }

    /**
     * Get device registration key for a sub-organization
     * @param orgId ID of the organization
     * @param subOrgId ID of the sub-organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getDeviceRegistrationKeySubOrg(
        orgId: string,
        subOrgId: string,
        authorization: string,
    ): CancelablePromise<{
        registration_key?: string;
        valid_till?: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/get_device_registration_key',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            headers: {
                'Authorization': authorization,
            },
        });
    }

    /**
     * Gets all device registration keys for the given org id.
     * @param orgId ID of the organization
     * @param subOrgId ID of the sub-organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns any OK
     * @throws ApiError
     */
    public static getOrgsSuborgsGetAllDeviceRegistrationKeys(
        orgId: string,
        subOrgId: string,
        authorization: string,
    ): CancelablePromise<{
        headers?: {
            'Access-Control-Allow-Credentials'?: boolean;
            'Access-Control-Allow-Origin'?: string;
        };
        statusCode?: number;
        body?: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/get_all_device_registration_keys',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                404: `No key found`,
            },
        });
    }

    /**
     * Gets all device registration keys for the given org and sub org.
     * @param orgId ID of the organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns any OK
     * @throws ApiError
     */
    public static getOrgsGetAllDeviceRegistrationKeys(
        orgId: string,
        authorization: string,
    ): CancelablePromise<{
        headers?: {
            'Access-Control-Allow-Credentials'?: boolean;
            'Access-Control-Allow-Origin'?: string;
        };
        statusCode?: number;
        body?: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/get_all_device_registration_keys',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                404: `No key found`,
            },
        });
    }

    /**
     * Delete specified device registration key for sub-organization
     * @param orgId
     * @param subOrgId
     * @param registrationKey
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns any Success
     * @throws ApiError
     */
    public static deleteSubOrgDeviceRegistrationKey(
        orgId: string,
        subOrgId: string,
        registrationKey: string,
        authorization: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/delete_device_registration_key/{registration_key}',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
                'registration_key': registrationKey,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                403: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Delete specified device registration key for organization
     * @param orgId
     * @param registrationKey
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns any Success
     * @throws ApiError
     */
    public static deleteOrgDeviceRegistrationKey(
        orgId: string,
        registrationKey: string,
        authorization: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/orgs/{org_id}/delete_device_registration_key/{registration_key}',
            path: {
                'org_id': orgId,
                'registration_key': registrationKey,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                403: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Send Messages
     * Send messages to multiple devices using a message queue protocol such as MQTT
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any All done
     * @throws ApiError
     */
    public static sendMessage(
        authorization: string,
        requestBody: {
            /**
             * Topic
             */
            topic: string;
            /**
             * Message
             */
            message: string;
            /**
             * QOS
             */
            qos: number;
        },
    ): CancelablePromise<{
        message?: string;
        success?: boolean;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/devices/send_message',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Register a new device and assign it to an organization or sub-organization based on the provided device registration key.
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns string Device registered successfully
     * @throws ApiError
     */
    public static registerDevice(
        authorization: string,
        requestBody: {
            /**
             * Registration key for the device
             */
            registration_key: string;
            /**
             * Serial number of the device
             */
            serial: string;
            /**
             * Operating System of the device
             */
            os?: string;
            /**
             * DeviceHub software version
             */
            dh_sw?: string;
            /**
             * Application software versions
             */
            app_sw_versions?: string;
            /**
             * Security patch version
             */
            security_patch_version?: string;
            /**
             * Date of provision
             */
            provisioned_date?: string;
            /**
             * Device certificate
             */
            device_certificate?: string;
            /**
             * Device topic
             */
            device_topic?: string;
        },
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/devices/register',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `A device with this serial number has already been registered under this organization`,
                404: `Registration key not found`,
                500: `Multiple entries found for the same registration key`,
            },
        });
    }

    /**
     * Get device metrics
     * @param serial Device Serial
     * @param timeFrom Date From
     * @param timeTo Date To
     * @param limit Number of items per page
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param sample Number of samples
     * @returns Metrics Metrics
     * @throws ApiError
     */
    public static getDevicesMetrics(
        serial: string,
        timeFrom: string,
        timeTo: string,
        limit: number,
        authorization: string,
        sample?: number,
    ): CancelablePromise<Array<Metrics>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/devices/{serial}/metrics',
            path: {
                'serial': serial,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'time_from': timeFrom,
                'time_to': timeTo,
                'limit': limit,
                'sample': sample,
            },
            errors: {
                400: `Bad Request. Invalid request body or parameters`,
                404: `Not Found. Bucket not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get device metrics
     * @param serial Device Serial
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns any Metrics
     * @throws ApiError
     */
    public static getDevicesVersions(
        serial: string,
        authorization: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/devices/{serial}/versions',
            path: {
                'serial': serial,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                400: `Bad Request. Invalid request body or parameters`,
                404: `Not Found. Bucket not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Request bugreport
     * Request bugreport
     * @param orgId Organization ID
     * @param deviceId Device ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Bugreport requested successfully
     * @throws ApiError
     */
    public static postOrgsDevicesDebugInit(
        orgId: string,
        deviceId: string,
        authorization: string,
        requestBody: {
            debug_type: string;
        },
    ): CancelablePromise<{
        message: DebugMessage;
        debug_info: DebugEntry;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/devices/{device_id}/debug/init',
            path: {
                'org_id': orgId,
                'device_id': deviceId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * List bugreports
     * List bugreports
     * @param orgId Organization ID
     * @param deviceId Device ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns any Devices retrieved successfully
     * @throws ApiError
     */
    public static getOrgsDevicesDebug(
        orgId: string,
        deviceId: string,
        authorization: string,
    ): CancelablePromise<{
        message: string;
        debug_entries: Array<DebugEntry>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/devices/{device_id}/debug',
            path: {
                'org_id': orgId,
                'device_id': deviceId,
            },
            headers: {
                'Authorization': authorization,
            },
        });
    }

    /**
     * Get bugreports
     * Get bugreports
     * @param orgId Organization ID
     * @param deviceId Device ID
     * @param debugId Debug ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns DebugEntry Bugreport retrieved successfully
     * @throws ApiError
     */
    public static getOrgsDeviceDebugDetails(
        orgId: string,
        deviceId: string,
        debugId: string,
        authorization: string,
    ): CancelablePromise<Array<DebugEntry>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/devices/{device_id}/debug/{debug_id}',
            path: {
                'org_id': orgId,
                'device_id': deviceId,
                'debug_id': debugId,
            },
            headers: {
                'Authorization': authorization,
            },
        });
    }

    /**
     * Delete bugreports
     * Delete bugreports
     * @param orgId Organization ID
     * @param deviceId Device ID
     * @param debugId Debug ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns ResponseMessage Report removed successfully
     * @throws ApiError
     */
    public static deleteOrgsDevicesDebug(
        orgId: string,
        deviceId: string,
        debugId: string,
        authorization: string,
    ): CancelablePromise<ResponseMessage> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/orgs/{org_id}/devices/{device_id}/debug/{debug_id}',
            path: {
                'org_id': orgId,
                'device_id': deviceId,
                'debug_id': debugId,
            },
            headers: {
                'Authorization': authorization,
            },
        });
    }

    /**
     * Get download url
     * Get download url
     * @param orgId Organization ID
     * @param deviceId Device ID
     * @param debugId Debug ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns any Retrieved successfully
     * @throws ApiError
     */
    public static getOrgsDevicesDebugDownload(
        orgId: string,
        deviceId: string,
        debugId: string,
        authorization: string,
    ): CancelablePromise<{
        message: string;
        download_url: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/devices/{device_id}/debug/{debug_id}/download',
            path: {
                'org_id': orgId,
                'device_id': deviceId,
                'debug_id': debugId,
            },
            headers: {
                'Authorization': authorization,
            },
        });
    }

}
