import * as React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import 'react-toastify/dist/ReactToastify.css';

let root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <App/>
);
