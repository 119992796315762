import { Container, Grid } from "@mui/material";
import React, { useState } from "react";
import { NavigateNext } from "@mui/icons-material";
import { MiscService } from "@src/rest";

const logoLight = require("../../../assets/images/logo-dark.png");

const ComingSoon = () => {

  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState<string | undefined>(undefined);

  function saveEmail(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    // @ts-ignore

    setMsg(undefined)
    MiscService.subscribeUpdates({ email }).then(response => {
      setMsg(response.message)
      setEmail("")
    }, err => {
      console.log(err)
    })
  }

  return (
    <Container>
      <Grid container className="justify-content-center">
        <Grid item lg={8} md={12} className="text-center">
          <img src={logoLight} height="44" className="l-dark" alt=""/>
          <div className="text-uppercase title-dark mt-2 mb-4 coming-soon">
            We're Coming soon...
          </div>
          <p className="  para-desc para-dark mx-auto">
              <span className="text-primary fw-bold">
              Real time, Reliable, Secure
              Device Management
              </span>
          </p>
        </Grid>

      </Grid>


      <div className="subcribe-form mt-4">
        <form onSubmit={(event) => saveEmail(event)}>
          <div className="mb-0">
            <input
              type="email"
              className={
                "border bg-white rounded-pill"
              }
              placeholder="Enter your email to get updates"
              required
              value={email}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(event.target.value);
              }}
            />
            <button type="submit" className="btn btn-pills btn-success">
              <NavigateNext className="  icon-sm  "
              />
            </button>
          </div>

        </form>
      </div>

      <Grid container className="justify-content-center">
        <Grid item lg={8} md={12} className="text-center">

          <p>
            {msg}
          </p>
        </Grid>

      </Grid>


    </Container>
  );
}

export default ComingSoon;
