/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Charges } from '../models/Charges';
import type { PaymentMethods } from '../models/PaymentMethods';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PaymentsService {

    /**
     * Get Stripe Config
     * Returns a publishable key
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getPaymentsConfig(
        authorization: string,
    ): CancelablePromise<{
        /**
         * publishable key
         */
        key: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/payments/config',
            headers: {
                'Authorization': authorization,
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * create a subscription
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Subscription created
     * @throws ApiError
     */
    public static postPaymentsCreateSubscription(
        authorization: string,
        requestBody?: {
            /**
             * Customer ID
             */
            customerId: string;
            /**
             * Payment Method Id
             */
            paymentMethodId: string;
            /**
             * Price Id
             */
            priceId: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/payments/create-subscription',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Error occurred`,
            },
        });
    }

    /**
     * gets user subscriptions
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Subscriptions list
     * @throws ApiError
     */
    public static postPaymentsGetSubscriptions(
        authorization: string,
        requestBody?: {
            /**
             * Customer ID
             */
            customerId: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/payments/get-subscriptions',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Error occurred`,
            },
        });
    }

    /**
     * cancel user subscription
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Cancelled subscription
     * @throws ApiError
     */
    public static postPaymentsCancelSubscription(
        authorization: string,
        requestBody?: {
            /**
             * Subscription ID
             */
            subscriptionId: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/payments/cancel-subscription',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Error occurred`,
            },
        });
    }

    /**
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static createCustomer(
        authorization: string,
        requestBody?: {
            /**
             * Email
             */
            email: string;
        },
    ): CancelablePromise<{
        id?: string;
        balance?: number;
        livemode?: boolean;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/payments/create-customer',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns PaymentMethods Successful operation
     * @throws ApiError
     */
    public static postPaymentsRetrieveCustomerPaymentMethods(
        authorization: string,
        requestBody?: {
            /**
             * Customer ID
             */
            customerId: string;
        },
    ): CancelablePromise<PaymentMethods> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/payments/retrieve-customer-payment-methods',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns Charges Successful operation
     * @throws ApiError
     */
    public static postPaymentsRetrieveCustomerCharges(
        authorization: string,
        requestBody?: {
            /**
             * Customer ID
             */
            customerId: string;
        },
    ): CancelablePromise<Charges> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/payments/retrieve-customer-charges',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
