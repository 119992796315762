import * as React from "react";
import { Suspense, useEffect } from "react";
import NavBar from "../content/public/components/NavBar";
import { Route, Routes } from "react-router-dom";
import Footer from "../content/public/components/Footer";
import { Backdrop, CircularProgress, Container } from "@mui/material";
import ComingSoon from "../content/public/components/ComingSoon";

const bg = require("../assets/images/devicehub/1.png");

const Login = React.lazy(() => import("../content/public/pages/login/Login"));
const Register = React.lazy(() => import("../content/public/pages/register/Register"));
const Confirm = React.lazy(() => import("../content/public/pages/confirm/Confirm"));
const LandingPage = React.lazy(() => import("../content/public/pages/home/LandingPage"));
const Documents = React.lazy(() => import("../content/public/pages/documents/Documents"));
const AboutUs = React.lazy(() => import("../content/public/pages/about-us/AboutUs"));
const ForgotPassword = React.lazy(() => import("../content/public/pages/forgot-password/ForgotPassword"));
const Pricing = React.lazy(() => import("../content/public/pages/pricing/Pricing"));

const routes = [
  { path: "/login", element: Login },
  { path: "/register", element: Register },
  { path: "/confirm", element: Confirm },
  { path: "/forgot-password", element: ForgotPassword },
  { path: "/documents", element: Documents },
  { path: "/about-us", element: AboutUs },
  { path: "/pricing", element: Pricing },
  { path: "/", element: LandingPage },
];

const loading = (<Backdrop
  sx={{ color: '#fff', minHeight: "100vh", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
  <CircularProgress color="inherit"/>
</Backdrop>);

const PublicLayout = () => {

  useEffect(() => {
    console.log(process.env)
  }, []);

  return (
    <React.Fragment>

      {process.env.REACT_APP_STAGE === "prod" &&
        <>
          <section className="section bg-light"
                   style={{ backgroundImage: `url(${bg})`, backgroundSize: "cover" }}>
            <ComingSoon/>
          </section>
        </>
      }

      {process.env.REACT_APP_STAGE !== "prod" &&
        <>
          <NavBar/>
          <section className="section bg-light"
                   style={{ backgroundImage: `url(${bg})`, backgroundSize: "contain" }}>

            <Container className=" min-vh-100">

              <Suspense fallback={loading}>
                <Routes>
                  {routes.map((route, idx) => {
                    return (
                      route.element && (
                        <Route key={idx} path={route.path} element={<route.element/>}/>
                      )
                    );
                  })}
                </Routes>
              </Suspense>
            </Container>
          </section>
          <Footer/>
        </>
      }

    </React.Fragment>
  );
};

export default PublicLayout;
