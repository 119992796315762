/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OTA } from '../models/OTA';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OtaService {

    /**
     * Create an OTA for a Sub-Organizations
     * Create an OTA update for a specified sub-organization. The endpoint requires an array of software packages each containing 'name' and 'version'. The packages defined here will extend or override the software images defined in the parent org
     * @param orgId Organization ID
     * @param subOrgId Sub-Organizations ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any OTA created successfully.
     * @throws ApiError
     */
    public static createSubOrgOta(
        orgId: string,
        subOrgId: string,
        authorization: string,
        requestBody: {
            sw_packages: Array<{
                /**
                 * Software name
                 */
                name: string;
                /**
                 * Software version
                 */
                version: string;
                /**
                 * Package Name
                 */
                package_name?: string;
            }>;
        },
    ): CancelablePromise<{
        /**
         * Presigned URLs for the OTA software packages
         */
        presigned_urls?: Array<string>;
        /**
         * Errors during the OTA creation, if any
         */
        errors?: Array<string>;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/create_ota',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request. The request could not be understood or was missing required parameters.`,
                401: `Unauthorized. The provided authorization token is missing, invalid, or expired.`,
                404: `Not Found. The specified organization or sub-organization could not be found.`,
            },
        });
    }

    /**
     * Create an OTA for an Organization
     * Create an OTA update for a specified organization. The endpoint requires an array of software packages each containing 'name' and 'version'.
     * @param orgId Organization ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any OTA created successfully.
     * @throws ApiError
     */
    public static createOrgOta(
        orgId: string,
        authorization: string,
        requestBody: {
            sw_packages: Array<{
                /**
                 * Software name
                 */
                name: string;
                /**
                 * Software version
                 */
                version: string;
                /**
                 * Package Name
                 */
                package_name?: string;
            }>;
        },
    ): CancelablePromise<{
        /**
         * Presigned URLs for the OTA software packages
         */
        presigned_urls?: Array<string>;
        /**
         * Errors during the OTA creation, if any
         */
        errors?: Array<string>;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/create_ota',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request. The request could not be understood or was missing required parameters.`,
                401: `Unauthorized. The provided authorization token is missing, invalid, or expired.`,
                404: `Not Found. The specified organization could not be found.`,
            },
        });
    }

    /**
     * Delete an OTA from a Sub-organization
     * Delete an OTA update for a specified sub-organization. The endpoint requires a software package containing 'name' and 'version'.
     * @param orgId Organization ID
     * @param subOrgId Sub-organization ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns string OTA deleted successfully.
     * @throws ApiError
     */
    public static deleteSubOrgOta(
        orgId: string,
        subOrgId: string,
        authorization: string,
        requestBody: {
            sw_package: {
                /**
                 * Software name
                 */
                name: string;
                /**
                 * Software version
                 */
                version: string;
            };
        },
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/delete_ota',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request. The request could not be understood or was missing required parameters.`,
                401: `Unauthorized. The provided authorization token is missing, invalid, or expired.`,
                404: `Not Found. The specified software or organization could not be found.`,
            },
        });
    }

    /**
     * Delete an OTA from an Organization
     * Delete an OTA update for a specified organization. The endpoint requires a software package containing 'name' and 'version'.
     * @param orgId Organization ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns string OTA deleted successfully.
     * @throws ApiError
     */
    public static deleteOrgOta(
        orgId: string,
        authorization: string,
        requestBody: {
            sw_package: {
                /**
                 * Software name
                 */
                name: string;
                /**
                 * Software version
                 */
                version: string;
            };
        },
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/delete_ota',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request. The request could not be understood or was missing required parameters.`,
                401: `Unauthorized. The provided authorization token is missing, invalid, or expired.`,
                404: `Not Found. The specified software or organization could not be found.`,
            },
        });
    }

    /**
     * List all OTAs for the organization
     * @param orgId ID of the organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns OTA List of OTAs
     * @throws ApiError
     */
    public static getOrgsListOtas(
        orgId: string,
        authorization: string,
    ): CancelablePromise<Array<OTA>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/list_otas',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                404: `Organization not found`,
            },
        });
    }

    /**
     * List all OTAs for the sub-organization
     * @param orgId ID of the organization
     * @param subOrgId ID of the sub-organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns OTA List of OTAs
     * @throws ApiError
     */
    public static getOrgsSuborgsListOtas(
        orgId: string,
        subOrgId: string,
        authorization: string,
    ): CancelablePromise<Array<OTA>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/list_otas',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                404: `Sub-organization not found`,
            },
        });
    }

    /**
     * Generate SW OTA Download URL for an organization
     * @param orgId Organization ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody Software package details
     * @returns any Success. Download URL is returned.
     * @throws ApiError
     */
    public static postOrgsOtaGetDownloadUrl(
        orgId: string,
        authorization: string,
        requestBody: {
            sw_package: {
                /**
                 * Software name
                 */
                name: string;
                /**
                 * Software version
                 */
                version: string;
            };
        },
    ): CancelablePromise<{
        /**
         * Download URL for the software package
         */
        download_url: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/ota/get_download_url',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request. The request could not be understood or was missing required parameters.`,
                401: `Unauthorized. The provided authorization token is missing, invalid, or expired.`,
                404: `Not Found. The requested software package could not be found.`,
            },
        });
    }

    /**
     * Generate SW OTA Download URL for a sub-organization
     * @param orgId Organization ID
     * @param subOrgId Sub-organization ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody Software package details
     * @returns any Success. Download URL is returned.
     * @throws ApiError
     */
    public static postOrgsSuborgsOtaGetDownloadUrl(
        orgId: string,
        subOrgId: string,
        authorization: string,
        requestBody: {
            sw_package: {
                /**
                 * Software name
                 */
                name: string;
                /**
                 * Software version
                 */
                version: string;
            };
        },
    ): CancelablePromise<{
        /**
         * Download URL for the software package
         */
        download_url: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/ota/get_download_url',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request. The request could not be understood or was missing required parameters.`,
                401: `Unauthorized. The provided authorization token is missing, invalid, or expired.`,
                404: `Not Found. The requested software package could not be found.`,
            },
        });
    }

}
