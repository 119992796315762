import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { auth } from "../../../Defines";

const logoLight = require("../../../assets/images/devicehub/logo-light.png");

const NavBar = () => {
  const [model, setModel] = useState<{ token: string | undefined }>({ token: undefined });

  useEffect(() => {
    const token = auth()
    setModel({ token: token as string })

    activateMenu();
  }, []);

  function activateMenu() {
    const menuItems = document.getElementsByClassName("sub-menu-item");
    if (menuItems) {

      let matchingMenuItem = null;
      for (let idx = 0; idx < menuItems.length; idx++) {
        // @ts-ignore
        if (menuItems[idx].href === window.location.href) {
          matchingMenuItem = menuItems[idx];
        }
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add("active");
        const immediateParent = matchingMenuItem.closest("li");
        if (immediateParent) {
          immediateParent.classList.add("active");
        }
        const parent = matchingMenuItem.closest(".parent-menu-item");
        if (parent) {
          parent.classList.add("active");
          const parentMenuitem = parent.querySelector(".menu-item");
          if (parentMenuitem) {
            parentMenuitem.classList.add("active");
          }
          const parentOfParent = parent.closest(".parent-menu-item-sub");
          if (parentOfParent) {
            parentOfParent.classList.add("active");
          }
        } else {
          const parentOfParent = matchingMenuItem.closest(".parent-menu-item-sub");
          if (parentOfParent) {
            parentOfParent.classList.add("active");
          }
        }

      }
    }
  }

  /**
   * Toggle menu in mobile screen
   */
  const toggleMenu = () => {
    const isToggle = document.getElementById("isToggle");
    if (isToggle) {
      isToggle.classList.toggle("open");
      const navigation = document.getElementById("navigation");
      if (navigation) {
        if (navigation.style.display === "block") {
          navigation.style.display = "none";
        } else {
          navigation.style.display = "block";
        }
      }
    }
  };

  return (
    <>
      <header id="topnav" className="defaultscroll sticky">
        <div className="container">

          <div>
            <Link className="logo" to="/">
                                <span className="logo-light-mode">
                                    <img src={logoLight} className="l-dark" height="44" alt=""/>
                                    <img src={logoLight} className="l-light" height="44" alt=""/>
                                </span>
              <img src={logoLight} height="54" className="logo-dark-mode" alt=""/>
            </Link>
          </div>


          <div className="menu-extras">
            <div className="menu-item">
              <Link to="#" className="navbar-toggle" id="isToggle" onClick={() => toggleMenu()}>
                <div className="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </Link>
            </div>
          </div>

          <ul className="buy-button list-inline mb-0">
            <li className="list-inline-item mb-0">
              {!model.token && <Link to="/register" className="btn btn-success">
                Sign Up
              </Link>
              }
              {model.token &&
                <Link to="/dash" className="btn btn-success">
                  Dashboard
                </Link>
              }
            </li>
          </ul>

          <div id="navigation">

            <ul className="navigation-menu nav-light align-items-end">
              <li><Link to="/documents" className="sub-menu-item">Documents</Link></li>
              <li><Link to="/pricing" className="sub-menu-item">Pricing</Link></li>
              <li><Link to="/about-us" className="sub-menu-item">About Us</Link></li>

              {!model.token &&
                <li><Link to="/login" className="sub-menu-item">Login</Link></li>
              }
            </ul>

          </div>

        </div>
      </header>

    </>
  );
};

export default NavBar;
