/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Device } from '../models/Device';
import type { Org } from '../models/Org';
import type { OTA } from '../models/OTA';
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrganizationsService {

    /**
     * Get orgs list
     * Get orgs list. Only an super admin user can perform this action.
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns Org Successful
     * @throws ApiError
     */
    public static listOrgs(
        authorization: string,
    ): CancelablePromise<Array<Org>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs',
            headers: {
                'Authorization': authorization,
            },
            errors: {
                403: `Permission denied`,
            },
        });
    }

    /**
     * Promote a user to organization admin
     * Promote a user to the 'admin' role in the organization. Only an admin user can perform this action.
     * @param orgId ID of the organization in which to promote the user
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Promotion successful
     * @throws ApiError
     */
    public static promoteUserToOrgAdmin(
        orgId: string,
        authorization: string,
        requestBody?: {
            /**
             * Email of the user to be promoted
             */
            target_email: string;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/make_admin',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Permission denied`,
                404: `User or organization not found`,
            },
        });
    }

    /**
     * Remove a user from the admin role in an organization
     * Demote a user from the 'admin' role in the organization. Only an admin user can perform this action.
     * @param orgId ID of the organization in which to demote the user
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Demotion successful
     * @throws ApiError
     */
    public static removeUserAsAdmin(
        orgId: string,
        authorization: string,
        requestBody?: {
            /**
             * Email of the user to be demoted
             */
            target_email: string;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/remove_admin',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Permission denied`,
            },
        });
    }

    /**
     * List all users in an organization
     * List all users in an organization, with their role (admin or user). This endpoint can be accessed only by the admin of the organization.
     * @param orgId ID of the organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns any List of users in the organization
     * @throws ApiError
     */
    public static listAllUsersInOrg(
        orgId: string,
        authorization: string,
    ): CancelablePromise<{
        message?: string;
        users?: Array<User>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/list_users',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                403: `Permission denied`,
            },
        });
    }

    /**
     * Create a sub-organization
     * Create a sub-organization under the parent organization. Only an admin user can perform this action.
     * @param orgId ID of the parent organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Sub-organization created successfully
     * @throws ApiError
     */
    public static createSubOrg(
        orgId: string,
        authorization: string,
        requestBody?: {
            sub_org_name: string;
        },
    ): CancelablePromise<{
        message?: string;
        sub_org_id?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/create_sub_orgs',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Sub-organization with the same name already exists`,
                403: `Permission denied`,
            },
        });
    }

    /**
     * Remove a sub-organization
     * Remove a sub-organization given the organization ID and the sub-organization ID. Only the admin of the organization has the permissions to remove a sub-organization.
     * @param orgId ID of the organization
     * @param subOrgId ID of the sub-organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns any Sub-organization removed successfully
     * @throws ApiError
     */
    public static removeSubOrg(
        orgId: string,
        subOrgId: string,
        authorization: string,
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * List all sub-organizations
     * List all sub-organizations under the parent organization.
     * @param orgId ID of the parent organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns any List of sub-organizations
     * @throws ApiError
     */
    public static listSubOrgs(
        orgId: string,
        authorization: string,
    ): CancelablePromise<{
        message?: string;
        sub_orgs?: Array<{
            sub_org_id?: string;
            sub_org_name?: string;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/list_sub_orgs',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                403: `Permission denied`,
            },
        });
    }

    /**
     * Add a device to an organization
     * This endpoint allows an admin of an organization to add a device to the organization.
     * @param orgId ID of the organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Successfully added the device
     * @throws ApiError
     */
    public static addDeviceToOrg(
        orgId: string,
        authorization: string,
        requestBody: {
            /**
             * Serial number of the device
             */
            serial: string;
            /**
             * Operating system of the device
             */
            os?: string;
            /**
             * DeviceHub software version
             */
            dh_sw?: string;
            /**
             * Application software versions
             */
            app_sw_versions?: string;
            /**
             * Security patch version of the device
             */
            security_patch_version?: string;
            /**
             * Date of provisioning the device
             */
            provisioned_date?: string;
            /**
             * Device certificate
             */
            device_certificate?: string;
            /**
             * Device topic
             */
            device_topic?: string;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/add_devices',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Device with this serial number already exists`,
                403: `Permission denied`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * List devices in an organization and its sub-organizations
     * This endpoint retrieves the devices in the given organization and its sub-organizations
     * @param orgId ID of the organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param cursor Cursor
     * @param limit Limit
     * @returns any Devices retrieved successfully
     * @throws ApiError
     */
    public static getOrgsListDevices(
        orgId: string,
        authorization: string,
        cursor?: string,
        limit?: string,
    ): CancelablePromise<{
        message?: string;
        devices: Array<Device>;
        cursor?: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/list_devices',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'cursor': cursor,
                'limit': limit,
            },
            errors: {
                403: `Forbidden`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Stats devices in an organization and its sub-organizations
     * This endpoint retrieves the devices in the given organization and its sub-organizations
     * @param orgId ID of the organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns any Devices retrieved successfully
     * @throws ApiError
     */
    public static getOrgsStatsDevices(
        orgId: string,
        authorization: string,
    ): CancelablePromise<{
        disconnected: number;
        connected: number;
        total: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/stats_devices',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                403: `Forbidden`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Stats devices in an organization and its sub-organizations
     * This endpoint retrieves the devices in the given organization and its sub-organizations
     * @param orgId ID of the organization
     * @param subOrgId Sub-Organizations ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns any Devices retrieved successfully
     * @throws ApiError
     */
    public static getOrgsSuborgsStatsDevices(
        orgId: string,
        subOrgId: string,
        authorization: string,
    ): CancelablePromise<{
        disconnected: number;
        connected: number;
        total: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/stats_devices',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                403: `Forbidden`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Add features to an organization
     * This endpoint allows you to add features to a specified organization.
     * @param orgId Organization ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody Features to add to the organization
     * @returns any Features added successfully.
     * @throws ApiError
     */
    public static addOrgFeatures(
        orgId: string,
        authorization: string,
        requestBody: {
            /**
             * A map of feature keys and their values.
             */
            features?: Record<string, string>;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/add_features',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request. The request could not be understood or was missing required parameters.`,
            },
        });
    }

    /**
     * Remove features from an organization
     * Removes specified features from an organization
     * @param orgId ID of the organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody List of features to remove
     * @returns any Features removed successfully
     * @throws ApiError
     */
    public static removeOrgFeatures(
        orgId: string,
        authorization: string,
        requestBody: {
            features?: Array<string>;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/remove_features',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * List features of organization
     * @param orgId ID of the organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns any List of features of the organization
     * @throws ApiError
     */
    public static getOrgsListFeatures(
        orgId: string,
        authorization: string,
    ): CancelablePromise<{
        key?: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/list_features',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                400: `Bad Request`,
                404: `Organization not found`,
            },
        });
    }

    /**
     * Create an OTA for an Organization
     * Create an OTA update for a specified organization. The endpoint requires an array of software packages each containing 'name' and 'version'.
     * @param orgId Organization ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any OTA created successfully.
     * @throws ApiError
     */
    public static createOrgOta(
        orgId: string,
        authorization: string,
        requestBody: {
            sw_packages: Array<{
                /**
                 * Software name
                 */
                name: string;
                /**
                 * Software version
                 */
                version: string;
                /**
                 * Package Name
                 */
                package_name?: string;
            }>;
        },
    ): CancelablePromise<{
        /**
         * Presigned URLs for the OTA software packages
         */
        presigned_urls?: Array<string>;
        /**
         * Errors during the OTA creation, if any
         */
        errors?: Array<string>;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/create_ota',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request. The request could not be understood or was missing required parameters.`,
                401: `Unauthorized. The provided authorization token is missing, invalid, or expired.`,
                404: `Not Found. The specified organization could not be found.`,
            },
        });
    }

    /**
     * Delete an OTA from an Organization
     * Delete an OTA update for a specified organization. The endpoint requires a software package containing 'name' and 'version'.
     * @param orgId Organization ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns string OTA deleted successfully.
     * @throws ApiError
     */
    public static deleteOrgOta(
        orgId: string,
        authorization: string,
        requestBody: {
            sw_package: {
                /**
                 * Software name
                 */
                name: string;
                /**
                 * Software version
                 */
                version: string;
            };
        },
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/delete_ota',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request. The request could not be understood or was missing required parameters.`,
                401: `Unauthorized. The provided authorization token is missing, invalid, or expired.`,
                404: `Not Found. The specified software or organization could not be found.`,
            },
        });
    }

    /**
     * List all OTAs for the organization
     * @param orgId ID of the organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns OTA List of OTAs
     * @throws ApiError
     */
    public static getOrgsListOtas(
        orgId: string,
        authorization: string,
    ): CancelablePromise<Array<OTA>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/list_otas',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                404: `Organization not found`,
            },
        });
    }

    /**
     * Generate SW OTA Download URL for an organization
     * @param orgId Organization ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody Software package details
     * @returns any Success. Download URL is returned.
     * @throws ApiError
     */
    public static postOrgsOtaGetDownloadUrl(
        orgId: string,
        authorization: string,
        requestBody: {
            sw_package: {
                /**
                 * Software name
                 */
                name: string;
                /**
                 * Software version
                 */
                version: string;
            };
        },
    ): CancelablePromise<{
        /**
         * Download URL for the software package
         */
        download_url: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/ota/get_download_url',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request. The request could not be understood or was missing required parameters.`,
                401: `Unauthorized. The provided authorization token is missing, invalid, or expired.`,
                404: `Not Found. The requested software package could not be found.`,
            },
        });
    }

}
