/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Job } from '../models/Job';
import type { ResponseMessage } from '../models/ResponseMessage';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class JobsService {

    /**
     * Get jobs
     * @param orgId Org Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns Job Job
     * @throws ApiError
     */
    public static getOrgsJobs(
        orgId: string,
        authorization: string,
    ): CancelablePromise<Array<Job>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/jobs',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                400: `Bad Request. Invalid request body or parameters`,
                404: `Not Found. Bucket not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create job
     * @param orgId Org Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns ResponseMessage Job
     * @throws ApiError
     */
    public static postOrgsJobs(
        orgId: string,
        authorization: string,
        requestBody: Job,
    ): CancelablePromise<ResponseMessage> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/jobs',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request. Invalid request body or parameters`,
                404: `Not Found. Bucket not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create job
     * @param orgId Org Id
     * @param subOrgId Suborg Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns Job Job
     * @throws ApiError
     */
    public static getOrgsSuborgsJobs(
        orgId: string,
        subOrgId: string,
        authorization: string,
    ): CancelablePromise<Array<Job>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/jobs',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                400: `Bad Request. Invalid request body or parameters`,
                404: `Not Found. Bucket not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create job
     * @param orgId Org Id
     * @param subOrgId Suborg Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns ResponseMessage Job
     * @throws ApiError
     */
    public static postOrgsSuborgsJobs(
        orgId: string,
        subOrgId: string,
        authorization: string,
        requestBody: Job,
    ): CancelablePromise<ResponseMessage> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/jobs',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request. Invalid request body or parameters`,
                404: `Not Found. Bucket not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete job
     * @param orgId Org Id
     * @param jobId Job Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns ResponseMessage Job
     * @throws ApiError
     */
    public static deleteOrgsJobs(
        orgId: string,
        jobId: string,
        authorization: string,
    ): CancelablePromise<ResponseMessage> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/orgs/{org_id}/jobs/{job_id}',
            path: {
                'org_id': orgId,
                'job_id': jobId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                400: `Bad Request. Invalid request body or parameters`,
                404: `Not Found. Bucket not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create job
     * @param orgId Org Id
     * @param subOrgId Suborg Id
     * @param jobId Job Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns ResponseMessage Job
     * @throws ApiError
     */
    public static deleteOrgsSuborgsJobs(
        orgId: string,
        subOrgId: string,
        jobId: string,
        authorization: string,
    ): CancelablePromise<ResponseMessage> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/jobs/{job_id}',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
                'job_id': jobId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                400: `Bad Request. Invalid request body or parameters`,
                404: `Not Found. Bucket not found`,
                500: `Internal server error`,
            },
        });
    }

}
