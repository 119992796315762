/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Device } from '../models/Device';
import type { OTA } from '../models/OTA';
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SubOrganizationsService {

    /**
     * List all users in a sub-organization
     * Fetches all users under the specified sub-organization.
     * @param orgId The ID of the organization
     * @param subOrgId The ID of the sub-organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns any Users in the sub-organization
     * @throws ApiError
     */
    public static listUsersInSubOrg(
        orgId: string,
        subOrgId: string,
        authorization: string,
    ): CancelablePromise<{
        message?: string;
        users?: Array<User>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/list_users',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            headers: {
                'Authorization': authorization,
            },
        });
    }

    /**
     * Create a sub-organization
     * Create a sub-organization under the parent organization. Only an admin user can perform this action.
     * @param orgId ID of the parent organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Sub-organization created successfully
     * @throws ApiError
     */
    public static createSubOrg(
        orgId: string,
        authorization: string,
        requestBody?: {
            sub_org_name: string;
        },
    ): CancelablePromise<{
        message?: string;
        sub_org_id?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/create_sub_orgs',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Sub-organization with the same name already exists`,
                403: `Permission denied`,
            },
        });
    }

    /**
     * List all sub-organizations
     * List all sub-organizations under the parent organization.
     * @param orgId ID of the parent organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns any List of sub-organizations
     * @throws ApiError
     */
    public static listSubOrgs(
        orgId: string,
        authorization: string,
    ): CancelablePromise<{
        message?: string;
        sub_orgs?: Array<{
            sub_org_id?: string;
            sub_org_name?: string;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/list_sub_orgs',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                403: `Permission denied`,
            },
        });
    }

    /**
     * Assign a user to a sub-organization
     * Assign a user to a sub-organization with the specified role (admin or user).
     * @param orgId ID of the parent organization
     * @param subOrgId ID of the sub-organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any User assigned to the sub-organization
     * @throws ApiError
     */
    public static assignUserToSubOrg(
        orgId: string,
        subOrgId: string,
        authorization: string,
        requestBody?: {
            target_email: string;
            role: string;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/assign',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Permission denied`,
                500: `Failed to retrieve organization data`,
            },
        });
    }

    /**
     * Make a user admin of a sub-organization
     * This endpoint allows an admin of an organization or a sub-organization to make a user an admin of the sub-organization.
     * @param orgId ID of the organization
     * @param subOrgId ID of the sub-organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Successfully made the user an admin
     * @throws ApiError
     */
    public static makeUserSubGroupAdmin(
        orgId: string,
        subOrgId: string,
        authorization: string,
        requestBody: {
            /**
             * Email of the user to be made admin
             */
            target_email: string;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/make_admin',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Permission denied`,
                500: `Failed to retrieve organization data`,
            },
        });
    }

    /**
     * Remove a user admin of a sub-organization
     * This endpoint allows an admin of an organization or a sub-organization to remove user as admin of the sub-organization.
     * @param orgId ID of the organization
     * @param subOrgId ID of the sub-organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Successfully made the user an admin
     * @throws ApiError
     */
    public static removeUserSubGroupAdmin(
        orgId: string,
        subOrgId: string,
        authorization: string,
        requestBody: {
            /**
             * Email of the user to be made admin
             */
            target_email: string;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/remove_admin',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Permission denied`,
                500: `Failed to retrieve organization data`,
            },
        });
    }

    /**
     * Remove a device from a sub-organization
     * This endpoint removes a device from a sub-organization and moves it back to the parent organization.
     * @param orgId Organization ID
     * @param subOrgId Sub-Organizations ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Device successfully removed from the sub-organization and moved back to the parent organization.
     * @throws ApiError
     */
    public static removeDeviceFromSubOrg(
        orgId: string,
        subOrgId: string,
        authorization: string,
        requestBody?: {
            /**
             * Device serial number
             */
            serial: string;
        },
    ): CancelablePromise<{
        /**
         * Success message
         */
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/remove_device',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `You don't have the required permissions to remove devices.`,
                404: `The specified sub_org or device does not exist.`,
            },
        });
    }

    /**
     * List all devices in a sub-organization
     * This endpoint returns a list of all devices that belong to a specified sub-organization.
     * @param orgId Organization ID
     * @param subOrgId Sub-Organizations ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param cursor Cursor
     * @param limit Limit
     * @returns any Devices successfully fetched.
     * @throws ApiError
     */
    public static listDevicesInSubOrg(
        orgId: string,
        subOrgId: string,
        authorization: string,
        cursor?: string,
        limit?: string,
    ): CancelablePromise<{
        message?: string;
        devices: Array<Device>;
        cursor?: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/list_devices',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'cursor': cursor,
                'limit': limit,
            },
            errors: {
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Move a device to a sub-organization
     * This endpoint allows an admin to move a device to a specific sub-organization
     * @param orgId ID of the organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Device successfully moved
     * @throws ApiError
     */
    public static postOrgsDevicesMoveToSuborg(
        orgId: string,
        authorization: string,
        requestBody: {
            /**
             * ID of the sub-organization
             */
            sub_org_id: string;
            /**
             * Serial number of the device
             */
            serial: string;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/devices/move_to_suborg',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Add features to a sub-organization
     * This endpoint allows you to add features to a specified sub-organization.
     * @param orgId Organization ID
     * @param subOrgId Sub-Organizations ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody Features to add to the sub-organization
     * @returns any Features added successfully.
     * @throws ApiError
     */
    public static addSubOrgFeatures(
        orgId: string,
        subOrgId: string,
        authorization: string,
        requestBody: {
            /**
             * A map of feature keys and their values.
             */
            features?: Record<string, string>;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/add_features',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request. The request could not be understood or was missing required parameters.`,
            },
        });
    }

    /**
     * Remove features from a sub-organization
     * Removes specified features from a sub-organization
     * @param orgId ID of the organization
     * @param subOrgName Name of the sub-organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody List of features to remove
     * @returns any Features removed successfully
     * @throws ApiError
     */
    public static removeSubOrgFeatures(
        orgId: string,
        subOrgName: string,
        authorization: string,
        requestBody: {
            features?: Array<string>;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/suborgs/{sub_org_name}/remove_features',
            path: {
                'org_id': orgId,
                'sub_org_name': subOrgName,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * List features of a sub-organization
     * This endpoint allows you to retrieve the features of a specified sub-organization.
     * @param orgId Organization ID
     * @param subOrgId Sub-Organizations ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns string Features retrieved successfully.
     * @throws ApiError
     */
    public static listSubOrgFeatures(
        orgId: string,
        subOrgId: string,
        authorization: string,
    ): CancelablePromise<Record<string, string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/list_features',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                400: `Bad request. The request could not be understood or was missing required parameters.`,
                401: `Unauthorized. The provided authorization token is missing, invalid or expired.`,
            },
        });
    }

    /**
     * Create an OTA for a Sub-Organizations
     * Create an OTA update for a specified sub-organization. The endpoint requires an array of software packages each containing 'name' and 'version'. The packages defined here will extend or override the software images defined in the parent org
     * @param orgId Organization ID
     * @param subOrgId Sub-Organizations ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any OTA created successfully.
     * @throws ApiError
     */
    public static createSubOrgOta(
        orgId: string,
        subOrgId: string,
        authorization: string,
        requestBody: {
            sw_packages: Array<{
                /**
                 * Software name
                 */
                name: string;
                /**
                 * Software version
                 */
                version: string;
                /**
                 * Package Name
                 */
                package_name?: string;
            }>;
        },
    ): CancelablePromise<{
        /**
         * Presigned URLs for the OTA software packages
         */
        presigned_urls?: Array<string>;
        /**
         * Errors during the OTA creation, if any
         */
        errors?: Array<string>;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/create_ota',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request. The request could not be understood or was missing required parameters.`,
                401: `Unauthorized. The provided authorization token is missing, invalid, or expired.`,
                404: `Not Found. The specified organization or sub-organization could not be found.`,
            },
        });
    }

    /**
     * Delete an OTA from a Sub-organization
     * Delete an OTA update for a specified sub-organization. The endpoint requires a software package containing 'name' and 'version'.
     * @param orgId Organization ID
     * @param subOrgId Sub-organization ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns string OTA deleted successfully.
     * @throws ApiError
     */
    public static deleteSubOrgOta(
        orgId: string,
        subOrgId: string,
        authorization: string,
        requestBody: {
            sw_package: {
                /**
                 * Software name
                 */
                name: string;
                /**
                 * Software version
                 */
                version: string;
            };
        },
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/delete_ota',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request. The request could not be understood or was missing required parameters.`,
                401: `Unauthorized. The provided authorization token is missing, invalid, or expired.`,
                404: `Not Found. The specified software or organization could not be found.`,
            },
        });
    }

    /**
     * List all OTAs for the sub-organization
     * @param orgId ID of the organization
     * @param subOrgId ID of the sub-organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns OTA List of OTAs
     * @throws ApiError
     */
    public static getOrgsSuborgsListOtas(
        orgId: string,
        subOrgId: string,
        authorization: string,
    ): CancelablePromise<Array<OTA>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/list_otas',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                404: `Sub-organization not found`,
            },
        });
    }

    /**
     * Generate SW OTA Download URL for a sub-organization
     * @param orgId Organization ID
     * @param subOrgId Sub-organization ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody Software package details
     * @returns any Success. Download URL is returned.
     * @throws ApiError
     */
    public static postOrgsSuborgsOtaGetDownloadUrl(
        orgId: string,
        subOrgId: string,
        authorization: string,
        requestBody: {
            sw_package: {
                /**
                 * Software name
                 */
                name: string;
                /**
                 * Software version
                 */
                version: string;
            };
        },
    ): CancelablePromise<{
        /**
         * Download URL for the software package
         */
        download_url: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/ota/get_download_url',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request. The request could not be understood or was missing required parameters.`,
                401: `Unauthorized. The provided authorization token is missing, invalid, or expired.`,
                404: `Not Found. The requested software package could not be found.`,
            },
        });
    }

}
