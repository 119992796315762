/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Alert } from '../models/Alert';
import type { ResponseMessage } from '../models/ResponseMessage';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AlertsService {

    /**
     * Get alerts
     * @param orgId Org Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns Alert Job
     * @throws ApiError
     */
    public static getOrgsAlertList(
        orgId: string,
        authorization: string,
    ): CancelablePromise<Array<Alert>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/alert/list',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                400: `Bad Request. Invalid request body or parameters`,
                404: `Not Found. Bucket not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Test alert
     * @param orgId Org Id
     * @param alertId Alert Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns Alert Alert
     * @throws ApiError
     */
    public static getOrgsAlertTest(
        orgId: string,
        alertId: string,
        authorization: string,
    ): CancelablePromise<Array<Alert>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/alert/{alert_id}/test',
            path: {
                'org_id': orgId,
                'alert_id': alertId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                400: `Bad Request. Invalid request body or parameters`,
                404: `Not Found. Bucket not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create alert
     * @param orgId Org Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns ResponseMessage Alert
     * @throws ApiError
     */
    public static postOrgsAlert(
        orgId: string,
        authorization: string,
        requestBody?: Alert,
    ): CancelablePromise<ResponseMessage> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/alert',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request. Invalid request body or parameters`,
                404: `Not Found. Bucket not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update alert
     * @param orgId Org Id
     * @param alertId Alert Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns ResponseMessage Alert
     * @throws ApiError
     */
    public static putOrgsAlert(
        orgId: string,
        alertId: string,
        authorization: string,
        requestBody?: Alert,
    ): CancelablePromise<ResponseMessage> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/orgs/{org_id}/alert/{alert_id}',
            path: {
                'org_id': orgId,
                'alert_id': alertId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request. Invalid request body or parameters`,
                404: `Not Found. Bucket not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete alert
     * @param orgId Org Id
     * @param alertId Alert Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns ResponseMessage Alert
     * @throws ApiError
     */
    public static deleteOrgsAlert(
        orgId: string,
        alertId: string,
        authorization: string,
    ): CancelablePromise<ResponseMessage> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/orgs/{org_id}/alert/{alert_id}',
            path: {
                'org_id': orgId,
                'alert_id': alertId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                400: `Bad Request. Invalid request body or parameters`,
                404: `Not Found. Bucket not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get suborg alerts
     * @param orgId Org Id
     * @param subOrgId Sub Org Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns Alert Job
     * @throws ApiError
     */
    public static getOrgsSuborgsAlertList(
        orgId: string,
        subOrgId: string,
        authorization: string,
    ): CancelablePromise<Array<Alert>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/alert/list',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                400: `Bad Request. Invalid request body or parameters`,
                404: `Not Found. Bucket not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create sub-org alert
     * @param orgId Org Id
     * @param subOrgId Sub Org Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns ResponseMessage Alert
     * @throws ApiError
     */
    public static postOrgsSuborgsAlert(
        orgId: string,
        subOrgId: string,
        authorization: string,
        requestBody?: Alert,
    ): CancelablePromise<ResponseMessage> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/alert',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request. Invalid request body or parameters`,
                404: `Not Found. Bucket not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update sub-org alert
     * @param orgId Org Id
     * @param subOrgId Sub Org Id
     * @param alertId Alert Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns ResponseMessage Alert
     * @throws ApiError
     */
    public static putOrgsSuborgsAlert(
        orgId: string,
        subOrgId: string,
        alertId: string,
        authorization: string,
        requestBody?: Alert,
    ): CancelablePromise<ResponseMessage> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/alert/{alert_id}',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
                'alert_id': alertId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request. Invalid request body or parameters`,
                404: `Not Found. Bucket not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete sub-org alert
     * @param orgId Org Id
     * @param subOrgId Sub Org Id
     * @param alertId Alert Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns ResponseMessage Alert
     * @throws ApiError
     */
    public static deleteOrgsSuborgsAlert(
        orgId: string,
        subOrgId: string,
        alertId: string,
        authorization: string,
    ): CancelablePromise<ResponseMessage> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/alert/{alert_id}',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
                'alert_id': alertId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                400: `Bad Request. Invalid request body or parameters`,
                404: `Not Found. Bucket not found`,
                500: `Internal server error`,
            },
        });
    }

}
