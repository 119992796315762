import * as React from "react";
import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import DashNavBar from "../content/dash/components/DashNavBar";
import { ToastContainer } from "react-toastify";

const DeviceDetails = React.lazy(() => import("../content/dash/pages/devices/DeviceDetails"));
const Users = React.lazy(() => import("../content/dash/pages/users/Users"));
const UserDetails = React.lazy(() => import("../content/dash/pages/users/UserDetails"));
const Organization = React.lazy(() => import("../content/dash/pages/organization/Organization"));
const Group = React.lazy(() => import("../content/dash/pages/group/Group"));
const Billing = React.lazy(() => import("../content/dash/pages/billing/Billing"));
const BucketsConfiguration = React.lazy(() => import("../content/dash/pages/administration/buckets-configuration/BucketsConfiguration"));
const Orgs = React.lazy(() => import("../content/dash/pages/administration/orgs/Orgs"));

const routes = [
  { path: "/buckets-configuration", element: BucketsConfiguration },
  { path: "/users", element: Users },
  { path: "/user/:email", element: UserDetails },
  { path: "/billing", element: Billing },
  { path: "/device/:serial", element: DeviceDetails },
  { path: "/orgs", element: Orgs },
  { path: "/organization", element: Organization },
  { path: "/group/:subOrgId", element: Group },
  { path: "/", element: Organization },
];

const loading = (<Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
  <CircularProgress color="inherit"/>
</Backdrop>);

const DashLayout = () => {
  return (
    <React.Fragment>
      <DashNavBar/>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Suspense fallback={loading}>
        <Routes>
          {routes.map((route, idx) => {
            return (
              route.element && (
                <Route key={idx} path={route.path} element={<route.element/>}/>
              )
            );
          })}
        </Routes>
      </Suspense>
    </React.Fragment>
  );
};

export default DashLayout;

