/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * Invite a new user
     * Invite a new user with the provided email
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any User invited successfully
     * @throws ApiError
     */
    public static inviteUser(
        authorization: string,
        requestBody: {
            email: string;
            org: string;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/invite',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
            },
        });
    }

    /**
     * Register a new user
     * Register a new user with the provided email, password, first name, and last name. Assigns the 'admin' role to the first user in the organization.
     * @param requestBody
     * @returns any User registered successfully
     * @throws ApiError
     */
    public static registerUser(
        requestBody: {
            email: string;
            first_name: string;
            last_name: string;
            phone_number?: string;
        },
    ): CancelablePromise<{
        message?: string;
        user?: User;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/register',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
            },
        });
    }

    /**
     * Login with temporary password
     * Login with temporary password.
     * @param requestBody
     * @returns any User login successfully
     * @throws ApiError
     */
    public static loginWithTemporaryPassword(
        requestBody: {
            email: string;
            temporary_password: string;
            new_password: string;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/login-with-temporary-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
            },
        });
    }

    /**
     * Get current authenticated user info
     * Get current authenticated user info
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns any Get current user data successfully
     * @throws ApiError
     */
    public static getUsersMe(
        authorization: string,
    ): CancelablePromise<{
        message?: string;
        user?: User;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/me',
            headers: {
                'Authorization': authorization,
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * Log in a user
     * Authenticate a user with the provided email and password. Returns user's details, organizations, sub-organizations, and user attributes.
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any User logged in successfully
     * @throws ApiError
     */
    public static loginUser(
        authorization: string,
        requestBody: {
            email: string;
            password: string;
        },
    ): CancelablePromise<{
        message?: string;
        /**
         * User token
         */
        token?: string;
        /**
         * List of organizations the user belongs to
         */
        organizations?: Array<string>;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/login',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Log out a user
     * Logout a user by instructing the client to discard the current tokens. This endpoint does not directly invalidate the tokens.
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns any Logout message
     * @throws ApiError
     */
    public static logoutUser(
        authorization: string,
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/logout',
            headers: {
                'Authorization': authorization,
            },
        });
    }

    /**
     * Send password reset instructions to the provided email address.
     * @param requestBody Email ID of the user for whom to initiate password recovery.
     * @returns any Password reset instructions sent to email
     * @throws ApiError
     */
    public static forgotPassword(
        requestBody: {
            /**
             * The email address of the user who forgot their password.
             */
            email: string;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/forgot-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Error message if any issue occurs.`,
            },
        });
    }

    /**
     * Confirm new password.
     * @param requestBody Email ID of the user for whom to initiate password recovery.
     * @returns any Password reset instructions sent to email
     * @throws ApiError
     */
    public static confirmNewPassword(
        requestBody: {
            /**
             * The email address of the user who forgot their password.
             */
            email: string;
            /**
             * Confirmation code from the email.
             */
            confirmation_code?: string;
            /**
             * New password.
             */
            password?: string;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/confirm-new-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Error message if any issue occurs.`,
            },
        });
    }

    /**
     * Promote a user to organization admin
     * Promote a user to the 'admin' role in the organization. Only an admin user can perform this action.
     * @param orgId ID of the organization in which to promote the user
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Promotion successful
     * @throws ApiError
     */
    public static promoteUserToOrgAdmin(
        orgId: string,
        authorization: string,
        requestBody?: {
            /**
             * Email of the user to be promoted
             */
            target_email: string;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/make_admin',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Permission denied`,
                404: `User or organization not found`,
            },
        });
    }

    /**
     * Remove a user from the admin role in an organization
     * Demote a user from the 'admin' role in the organization. Only an admin user can perform this action.
     * @param orgId ID of the organization in which to demote the user
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Demotion successful
     * @throws ApiError
     */
    public static removeUserAsAdmin(
        orgId: string,
        authorization: string,
        requestBody?: {
            /**
             * Email of the user to be demoted
             */
            target_email: string;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/remove_admin',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Permission denied`,
            },
        });
    }

    /**
     * List all users in an organization
     * List all users in an organization, with their role (admin or user). This endpoint can be accessed only by the admin of the organization.
     * @param orgId ID of the organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns any List of users in the organization
     * @throws ApiError
     */
    public static listAllUsersInOrg(
        orgId: string,
        authorization: string,
    ): CancelablePromise<{
        message?: string;
        users?: Array<User>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/list_users',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                403: `Permission denied`,
            },
        });
    }

    /**
     * Assign a user to a sub-organization
     * Assign a user to a sub-organization with the specified role (admin or user).
     * @param orgId ID of the parent organization
     * @param subOrgId ID of the sub-organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any User assigned to the sub-organization
     * @throws ApiError
     */
    public static assignUserToSubOrg(
        orgId: string,
        subOrgId: string,
        authorization: string,
        requestBody?: {
            target_email: string;
            role: string;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/assign',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Permission denied`,
                500: `Failed to retrieve organization data`,
            },
        });
    }

    /**
     * Make a user admin of a sub-organization
     * This endpoint allows an admin of an organization or a sub-organization to make a user an admin of the sub-organization.
     * @param orgId ID of the organization
     * @param subOrgId ID of the sub-organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Successfully made the user an admin
     * @throws ApiError
     */
    public static makeUserSubGroupAdmin(
        orgId: string,
        subOrgId: string,
        authorization: string,
        requestBody: {
            /**
             * Email of the user to be made admin
             */
            target_email: string;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/make_admin',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Permission denied`,
                500: `Failed to retrieve organization data`,
            },
        });
    }

    /**
     * Remove a user admin of a sub-organization
     * This endpoint allows an admin of an organization or a sub-organization to remove user as admin of the sub-organization.
     * @param orgId ID of the organization
     * @param subOrgId ID of the sub-organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Successfully made the user an admin
     * @throws ApiError
     */
    public static removeUserSubGroupAdmin(
        orgId: string,
        subOrgId: string,
        authorization: string,
        requestBody: {
            /**
             * Email of the user to be made admin
             */
            target_email: string;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/remove_admin',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Permission denied`,
                500: `Failed to retrieve organization data`,
            },
        });
    }

}
