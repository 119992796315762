import React, { Suspense, useState } from "react";
import { Link } from "react-router-dom";

import { Facebook, Instagram, LinkedIn, NavigateNext, Twitter } from "@mui/icons-material";
import BackToTop from "./BackToTop";
import { Container, Grid } from "@mui/material";
import { MiscService } from "@src/rest";

const logoWhite = require("../../../assets/images/devicehub/logo-white.png");

const Loader = () => {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
};

const Footer = () => {

  const grid2 = [
    { title: "Contact Us", link: "/contact-us" },
    { title: "About Us", link: "/about-us" },
    { title: "Terms of Service", link: "/terms" }
  ];

  const [msg, setMsg] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState('');

  function saveEmail(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    // @ts-ignore

    setMsg(undefined)
    MiscService.subscribeUpdates({ email }).then(response => {
      setMsg(response.message)
      setEmail("")
    }, err => {
      console.log(err)
    })
  }

  return (
    <React.Fragment>


      <Suspense fallback={Loader()}>
        <footer className="footer">
          <Container>
            <Grid container className="p-4">
              <Grid item className="col-12">
                <div className="footer-py-30">
                  <Grid container>
                    <Grid item lg={4}
                          className="col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                      <Link to="#" className="logo-footer">
                        <img src={logoWhite} height="44" alt=""/>
                      </Link>
                      <ul className="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                        <li className="list-inline-item">
                          <Link to="#" className="rounded me-1">
                            <Facebook
                              className="fea icon-sm fea-social"
                            />
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link to="#" className="rounded me-1">
                            <Instagram
                              className="fea icon-sm fea-social"
                            />
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link to="#" className="rounded me-1">
                            <Twitter
                              className="fea icon-sm fea-social"
                            />
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link to="#" className="rounded me-1">
                            <LinkedIn
                              className="fea icon-sm fea-social"
                            />
                          </Link>
                        </li>
                      </ul>
                    </Grid>

                    <Grid item
                          lg={4}
                          md={4}
                          className="col-12 mt-sm-0 pt-sm-0">
                      <ul className="list-unstyled footer-list mt-2">
                        {grid2.map((grid, key) => (
                          <li key={key}>
                            <Link to={grid.link} className={"text-muted"}>
                              <i className="uil uil-angle-right-b me-1"></i>{" "}
                              {grid.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </Grid>

                    <Grid item
                          lg={4}
                          md={4}
                          className="col-12 mt-sm-0 pt-2 pt-sm-0">
                      <p className="pt-1">
                        Get Latest updates!
                      </p>
                      <div className="subcribe-form">
                        <form onSubmit={(event) => saveEmail(event)}>
                          <div className="mb-0">
                            <input
                              type="email"
                              className={
                                "border bg-white rounded-pill"
                              }
                              placeholder="Enter your email:"
                              required
                              value={email}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setEmail(event.target.value);
                              }}
                            />
                            <button type="submit" className="btn btn-pills btn-success">
                              <NavigateNext className="  icon-sm  "
                              />
                            </button>
                          </div>

                          <p className="">
                            {msg}
                          </p>
                        </form>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Container>

          <div className="footer-py-3 footer-bar">
            <Container className="text-center">
              <Grid container className="d-flex justify-content-center">
                <Grid item sm={6}>
                  <div className="align-items-center">
                    <p className="mb-0 p-1 small"
                       style={{ color: "#597999", fontSize: "10px" }}>© {(new Date().getFullYear())} Devicehub.ai |
                      All Rights
                      Reserved</p>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </footer>

        <BackToTop/>

      </Suspense>
    </React.Fragment>
  );
};

export default Footer;
