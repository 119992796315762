import "./Apps.scss";
import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import PublicLayout from './layout/PublicLayout';
import DashLayout from "./layout/DashLayout";
import secureLocalStorage from "react-secure-storage";
import { Authorization } from "./Defines";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material";

import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey('YOUR_LICENSE_KEY');

export function RequireAuth({ children }: any) {
  const token = secureLocalStorage.getItem(Authorization)
  if (!token) {
    return <Navigate to="/login" replace/>;
  }
  return children;
}

const theme = createTheme({
  typography: {
    fontFamily: 'Raleway, Arial',
  },
  palette: {
    primary: {
      light: '#112d4e',
      main: '#112d4e',
      dark: '#112d4e',
      contrastText: '#fff',
    },
    secondary: {
      light: '#19bfb7',
      main: '#19bfb7',
      dark: '#19bfb7',
      contrastText: '#000',
    },
    warning: {
      light: '#da594e',
      main: '#DB4437',
      dark: '#d73123',
      contrastText: '#fff',
    },
    info: {
      light: '#6c9dff',
      main: '#5890FF',
      dark: '#4382fc',
      contrastText: '#fff',
    },
  },
});

function App() {

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>

        <Routes>

          <Route path="dash/*" element={
            <RequireAuth>
              <DashLayout/>
            </RequireAuth>
          }/>

          <Route path="/*" element={
            <PublicLayout/>
          }/>

        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
