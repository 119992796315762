import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PersonPin } from "@mui/icons-material";
import secureLocalStorage from "react-secure-storage";
import { auth, Authorization, Me, org } from "@src/Defines";
import { User, UsersService } from "@src/rest";

const logoLight = require("../../../assets/images/devicehub/logo-light.png");

const DashNavBar = () => {

  const [me, setMe] = useState<User | undefined>(undefined);

  useEffect(() => {
    UsersService.getUsersMe(auth()).then(response => {
      console.log(response)
      setMe(response.user)
      secureLocalStorage.setItem(Me, response.user ?? {})

    }, err => {
      console.error(err)
    })
    activateMenu();
  }, []);

  function logout() {
    UsersService.logoutUser(auth()).then(response => {
        secureLocalStorage.removeItem(Authorization)
        window.location.reload()
      }, err => {
        secureLocalStorage.removeItem(Authorization)
        window.location.reload()
      }
    )
  }

  function activateMenu() {
    const menuItems = document.getElementsByClassName("sub-menu-item");
    if (menuItems) {

      let matchingMenuItem = null;
      for (let idx = 0; idx < menuItems.length; idx++) {
        // @ts-ignore
        if (menuItems[idx].href === window.location.href) {
          matchingMenuItem = menuItems[idx];
        }
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add("active");
        const immediateParent = matchingMenuItem.closest("li");
        if (immediateParent) {
          immediateParent.classList.add("active");
        }
        const parent = matchingMenuItem.closest(".parent-menu-item");
        if (parent) {
          parent.classList.add("active");
          const parentMenuitem = parent.querySelector(".menu-item");
          if (parentMenuitem) {
            parentMenuitem.classList.add("active");
          }
          const parentOfParent = parent.closest(".parent-menu-item-sub");
          if (parentOfParent) {
            parentOfParent.classList.add("active");
          }

        } else {
          const parentOfParent = matchingMenuItem.closest(".parent-parent-menu-item");
          if (parentOfParent) {
            parentOfParent.classList.add("active");
          }
        }

      }
    }
  }

  /**
   * Toggle menu in mobile screen
   */
  const isToggleMenu = () => {
    const isToggle = document.getElementById("isToggle");
    if (isToggle) {
      isToggle.classList.toggle("open");
      const navigation = document.getElementById("navigation");
      if (navigation) {
        if (navigation.style.display === "block") {
          navigation.style.display = "none";
        } else {
          navigation.style.display = "block";
        }
      }
    }
  };

  return (
    <>
      <header id="topnav" className="defaultscroll sticky">
        <div className="container">

          <Link className="logo" to="/">
                                <span className="logo-light-mode">
                                    <img src={logoLight} height="44" className="l-dark" alt=""/>
                                    <img src={logoLight} height="44" className="l-light" alt=""/>
                                </span>
            <img src={logoLight} height="54" className="logo-dark-mode" alt=""/>
          </Link>
          <div className="menu-extras">
            <div className="menu-item">
              <Link to="#" className="navbar-toggle" id="isToggle" onClick={() => isToggleMenu()}>
                <div className="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </Link>
            </div>
          </div>

          <div id="navigation">
            <ul className={"navigation-menu  nav-light nav-right"}>
              <li><Link to="/dash/organization" className="sub-menu-item">{org()}</Link></li>

              {me?.role === User.role.ADMIN &&
                <>
                  <li><Link to="/dash/users" className="sub-menu-item">Users</Link></li>
                  <li><Link to="/dash/billing" className="sub-menu-item">Billing</Link></li>

                  {(me.organization_id === "goodintent.ai" || me.organization_id === "devicehub.ai") &&
                    <li className="has-submenu parent-parent-menu-item">
                      <Link to="#">
                        Administration
                      </Link>
                      <ul className="submenu">
                        <li><Link to="/dash/buckets-configuration" className="sub-menu-item">Buckets
                          Configuration</Link></li>
                        <li><Link to="/dash/orgs" className="sub-menu-item">Organizations</Link></li>
                      </ul>
                    </li>
                  }
                </>
              }

              <li className="has-submenu parent-parent-menu-item">
                <Link to="#">
                  <PersonPin/>
                </Link>
                <ul className="submenu">
                  <li><Link to="#" onClick={() => logout()} className="sub-menu-item">Logout</Link></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </header>

    </>
  );
};

export default DashNavBar;
