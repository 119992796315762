import secureLocalStorage from "react-secure-storage";

export var Authorization = "token"
export var Organization = "organization"
export var Me = "user"

const parseJwt = (token: string) => {
  const decode = JSON.parse(atob(token.split('.')[1]));
  if (decode.exp * 1000 < new Date().getTime()) {
    secureLocalStorage.removeItem(Authorization)
    window.location.reload()
  }
};

export const auth = () => {
  const item = secureLocalStorage.getItem(Authorization) as string | null
  if (item) {
    parseJwt(item)
    return item
  }
  return ""
};

export const org = () => secureLocalStorage.getItem(Organization) as string ?? "";
export const user = () => secureLocalStorage.getItem(Me) as {
  message?: string;
  organization_id?: string;
  user_id?: string;
  role?: 'admin' | 'user';
};


export const getFieldType = (value: string) => {
  if (value === 'true' || value === 'false') {
    return 'bool';
  } else if (/^-?\d+$/.test(value)) {
    return 'int';
  } else if (/^-?\d+\.\d+$/.test(value)) {
    return 'float';
  } else if (/^-?\d+n$/.test(value)) {
    return 'long';
  } else {
    return 'string';
  }
};
