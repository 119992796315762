/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ResponseMessage } from '../models/ResponseMessage';
import type { Rollout } from '../models/Rollout';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RolloutService {

    /**
     * Initialize rollout
     * @param orgId Org Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns Rollout Rollout Id
     * @throws ApiError
     */
    public static getOrgsRolloutList(
        orgId: string,
        authorization: string,
    ): CancelablePromise<Array<Rollout>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/rollout/list',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                400: `Bad Request. Invalid request body or parameters`,
                404: `Not Found. Bucket not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Initialize rollout
     * @param orgId Org Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Rollout Id
     * @throws ApiError
     */
    public static postOrgsRollout(
        orgId: string,
        authorization: string,
        requestBody: {
            feature_or_update_ref: string;
        },
    ): CancelablePromise<{
        message: string;
        rollout_id: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/rollout',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request. Invalid request body or parameters`,
                404: `Not Found. Bucket not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete rollout
     * @param orgId Org Id
     * @param rolloutId Org Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns ResponseMessage Rollout Id
     * @throws ApiError
     */
    public static deleteOrgsRollout(
        orgId: string,
        rolloutId: string,
        authorization: string,
    ): CancelablePromise<ResponseMessage> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/orgs/{org_id}/rollout/{rollout_id}',
            path: {
                'org_id': orgId,
                'rollout_id': rolloutId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                400: `Bad Request. Invalid request body or parameters`,
                404: `Not Found. Bucket not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get rollout
     * @param orgId Org Id
     * @param rolloutId Org Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns any Rollout Id
     * @throws ApiError
     */
    public static getOrgsRolloutNextStage(
        orgId: string,
        rolloutId: string,
        authorization: string,
    ): CancelablePromise<{
        message: string;
        next_stage_name?: string;
        next_stage_percentage?: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/rollout/{rollout_id}/next_stage',
            path: {
                'org_id': orgId,
                'rollout_id': rolloutId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                400: `Bad Request. Invalid request body or parameters`,
                404: `Not Found. Bucket not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Start next rollout
     * @param orgId Org Id
     * @param rolloutId Org Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns ResponseMessage Rollout Id
     * @throws ApiError
     */
    public static postOrgsRolloutStartNext(
        orgId: string,
        rolloutId: string,
        authorization: string,
    ): CancelablePromise<ResponseMessage> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/rollout/{rollout_id}/start_next',
            path: {
                'org_id': orgId,
                'rollout_id': rolloutId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                400: `Bad Request. Invalid request body or parameters`,
                404: `Not Found. Bucket not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Start next rollout
     * @param orgId Org Id
     * @param rolloutId Org Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns ResponseMessage Rollout Id
     * @throws ApiError
     */
    public static postOrgsRolloutStartRollout(
        orgId: string,
        rolloutId: string,
        authorization: string,
    ): CancelablePromise<ResponseMessage> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/rollout/{rollout_id}/start_rollout',
            path: {
                'org_id': orgId,
                'rollout_id': rolloutId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                400: `Bad Request. Invalid request body or parameters`,
                404: `Not Found. Bucket not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Initialize rollout
     * @param orgId Org Id
     * @param subOrgId Suborg Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns Rollout Rollout Id
     * @throws ApiError
     */
    public static getOrgsSuborgsRolloutList(
        orgId: string,
        subOrgId: string,
        authorization: string,
    ): CancelablePromise<Array<Rollout>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/rollout/list',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                400: `Bad Request. Invalid request body or parameters`,
                404: `Not Found. Bucket not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Initialize rollout
     * @param orgId Org Id
     * @param subOrgId Suborg Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Rollout Id
     * @throws ApiError
     */
    public static postOrgsSuborgsRollout(
        orgId: string,
        subOrgId: string,
        authorization: string,
        requestBody: {
            feature_or_update_ref: string;
        },
    ): CancelablePromise<{
        message: string;
        rollout_id: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/rollout',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request. Invalid request body or parameters`,
                404: `Not Found. Bucket not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get rollout
     * @param orgId Org Id
     * @param subOrgId Suborg Id
     * @param rolloutId Org Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns any Rollout Id
     * @throws ApiError
     */
    public static getOrgsSuborgsRolloutNextStage(
        orgId: string,
        subOrgId: string,
        rolloutId: string,
        authorization: string,
    ): CancelablePromise<{
        message: string;
        next_stage_name?: string;
        next_stage_percentage?: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/rollout/{rollout_id}/next_stage',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
                'rollout_id': rolloutId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                400: `Bad Request. Invalid request body or parameters`,
                404: `Not Found. Bucket not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Start next rollout
     * @param orgId Org Id
     * @param subOrgId Suborg Id
     * @param rolloutId Org Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns ResponseMessage Rollout Id
     * @throws ApiError
     */
    public static postOrgsSuborgsRolloutStartNext(
        orgId: string,
        subOrgId: string,
        rolloutId: string,
        authorization: string,
    ): CancelablePromise<ResponseMessage> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/rollout/{rollout_id}/start_next',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
                'rollout_id': rolloutId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                400: `Bad Request. Invalid request body or parameters`,
                404: `Not Found. Bucket not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Start next rollout
     * @param orgId Org Id
     * @param subOrgId Suborg Id
     * @param rolloutId Org Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns ResponseMessage Rollout Id
     * @throws ApiError
     */
    public static postOrgsSuborgsRolloutStartRollout(
        orgId: string,
        subOrgId: string,
        rolloutId: string,
        authorization: string,
    ): CancelablePromise<ResponseMessage> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs/{org_id}/suborgs/{sub_org_id}/rollout/{rollout_id}/start_rollout',
            path: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
                'rollout_id': rolloutId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                400: `Bad Request. Invalid request body or parameters`,
                404: `Not Found. Bucket not found`,
                500: `Internal server error`,
            },
        });
    }

}
